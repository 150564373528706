import React from 'react';
import { Grid, Box, Typography, Card, Button } from '@mui/material';
import bg3 from '../Images/bg3.png';


const Industrieswecater = () => {
  return (
    <Grid id="Services" container padding={'3%'} sx={{ paddingTop: '100px', backgroundImage: `url(${bg3})` }}>
      <Grid container>

        <Grid item lg={12} xs={12} display={"flex"} justifyContent={'center'}>
          <Box sx={{ backgroundColor: 'rgb(137 110 255 / 10%)', padding: '10px 30px', display: 'inline-block', borderRadius: '10px', marginBottom: '30px' }}>
            <Typography sx={{ fontSize: '18px', fontWeight: '600', color: '#896eff' }}> Industries We Cater To</Typography>
          </Box>
        </Grid>
      </Grid>

      <Grid container >
        <Grid item lg={1}></Grid>
        < Grid container item xs={12} lg={10} md={12} sm={12}>
          <Grid item xs={12} lg={4} md={4} sm={4}>
            <Card sx={{ boxShadow: 'none', padding: '41px', borderRadius: '10px', margin: '4%', textAlign: 'center', justify: 'center', alignContent: 'center', height: '75%', "&:hover": { boxShadow: "0 0px 10px 4px rgb(31 66 135 / 10%)" } }}>

              <div style={{ borderRadius: '50%', boxShadow: '0 2px 15px rgb(255 168 8 / 50%)', backgroundColor: '#ffa808', height: '90px', width: '90px', margin: '4% auto 12%' }}></div>
              <Typography sx={{ fontFamily: 'Source Sans Pro', fontSize: '1.5625rem', fontWeight: '700', color: '#1f2471', marginBottom: '15px' }}>
                Education
              </Typography>
              <Typography sx={{ fontFamily: 'Source Sans Pro', fontSize: '17px', fontWeight: '400', color: '#505489', marginBottom: '25px' }}>
                Powered by Edx, explore our world class e-learning platform
              </Typography>

              <a href='' style={{ fontFamily: 'Source Sans Pro', fontSize: '18px', fontWeight: '600', color: '#896eff', textDecoration: 'none', cursor: 'pointer' }}>
                Learn More
              </a>


            </Card>
          </Grid>
          <Grid item xs={12} lg={4} md={4} sm={4}>
            <Card sx={{ boxShadow: 'none', padding: '30px', borderRadius: '10px', margin: '4%', textAlign: 'center', justify: 'center', alignContent: 'center', height: '80%', "&:hover": { boxShadow: "0 0px 10px 4px rgb(31 66 135 / 10%)" } }}>

              <div style={{ borderRadius: '50%', boxShadow: '0 2px 15px rgb(255 88 110 / 50%)', backgroundColor: '#ff586e', height: '90px', width: '90px', margin: '4% auto 12%' }}></div>
              <Typography sx={{ fontFamily: 'Source Sans Pro', fontSize: '1.5625rem', fontWeight: '700', color: '#1f2471', marginBottom: '15px' }}>
                Art & Creativity
              </Typography>
              <Typography sx={{ fontFamily: 'Source Sans Pro', fontSize: '17px', fontWeight: '400', color: '#505489', marginBottom: '25px' }}>
                Curated especially for creators, this e-commerce platform offers ample of opportunities to display your creatives and sell them too
              </Typography>
              <Button variant="contained" sx={{ backgroundColor: 'rgb(137 110 255)', textTransform: 'capitalize', fontSize: '15px' }}>Marketplace</Button>
            </Card>
          </Grid>
          <Grid item xs={12} lg={4} md={4} sm={4}>
            <Card sx={{ boxShadow: 'none', padding: '30px', borderRadius: '10px', margin: '4%', textAlign: 'center', justify: 'center', alignContent: 'center', height: '80%', "&:hover": { boxShadow: "0 0px 10px 4px rgb(31 66 135 / 10%)" } }}>
              <div style={{ borderRadius: '50%', boxShadow: '0 2px 15px rgb(2 158 118 / 50%)', backgroundColor: '#029e76', height: '90px', width: '90px', margin: '4% auto 12%' }}></div>
              <Typography sx={{ fontFamily: 'Source Sans Pro', fontSize: '1.5625rem', fontWeight: '700', color: '#1f2471', marginBottom: '15px' }}>
                Agriculture
              </Typography>
              <Typography sx={{ fontFamily: 'Source Sans Pro', fontSize: '17px', fontWeight: '400', color: '#505489', marginBottom: '25px' }}>
                Explore our e-hub especially designed for our brothers and sisters from the agricultural society
              </Typography>
              <Typography sx={{ fontFamily: 'Source Sans Pro', fontSize: '18px', fontWeight: '600', color: '#008f49' }}>
                Launching Soon...
              </Typography>
            </Card>
          </Grid>
        </Grid>
        <Grid item lg={1}></Grid>
      </Grid>
    </Grid>
  )
}

export default Industrieswecater
