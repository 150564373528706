import React from "react";
import Nav from "./components/Nav";
import Realizeyourvision from "./components/Realizeyourvision";
import Industrieswecater from "./components/Industrieswecater";
import AboutUs from "./components/AboutUs";
import Platform from "./components/Platform";
import Features from "./components/Features";
import Expertise from "./components/Expertise";
import Footer from "./components/Footer";
import SwipeableTextMobileStepper from "./components/Carousel";
import Contact from "./components/Contact";
import Blog from "./components/Blog";

<cross-domain-policy>
<allow-access-from domain="ielts.keewenterprise.com" />
</cross-domain-policy>

const App = () => {
  return ( 
    <div>
      <Nav />
      <Realizeyourvision />
      <Industrieswecater />
      <AboutUs /> 
      <Platform />
      <Features /> 
      <Expertise />
      {/* <Blog /> */}
      <Contact />
      <Footer />
    </div>
  );
};

export default App;
