import React from 'react';
import {Divider, Grid, Typography, Box, Button, Link} from '@mui/material';
import GurbizLogo from '../Images/GurbizLogo.png';
import CopyrightIcon from '@mui/icons-material/Copyright';

import './style.css'


export default function Footer(){
    return(
        <>
        <Grid container
        sx={{backgroundColor:'#896eff'}}
        >

        <Grid item xs={1} lg={1.5}/>
          <Grid item xs={12} lg={4} marginTop='5%'>
           <img src={GurbizLogo} alt='image' style={{height:'46px', width:'128px'}}/>
          </Grid>
           
          <Grid item xs={1} lg={1}/>
          <Grid item xs={12} lg={4} marginTop='5%' marginBottom='1%'>
           <Typography variant='h6' sx={{color:'white',fontFamily:'Source Sans Pro', marginLeft:'6%'}}>
             18001039313<br/>
             bdgurbiz@glsoftservice.com
           </Typography>
          </Grid>
        <Grid item xs={1} lg={1.5}/>
        
        <Grid item xs={1} lg={1.5}></Grid>
        <Grid item xs={12} lg={9} marginBottom='2%'>
          <Box sx={{height:'1px', width:'97%', backgroundColor:'white', }} ></Box>
        </Grid>
          
          
          
           <Grid item xs={1} lg={1.6}/>
          <Grid item xs={6} lg={2.5} md={6} >
            <Typography variant='h5' sx={{color:'white',fontFamily:'Source Sans Pro', fontWeight:'bold'}}>
            About Us
            </Typography>
            <div style={{display:'flex'}}>
            <Box sx={{height:'4px', width:'15px', backgroundColor:'white', borderRadius:'4px', marginBottom:'6%', marginRight:'2%'}}></Box>
            <Box sx={{height:'4px', width:'45px', backgroundColor:'white', borderRadius:'4px', marginBottom:'12%'}}></Box>
            </div>
            <Typography  sx={{color:'white',fontFamily:'Source Sans Pro', fontSize:'17px', width:'90%'}}>
            Welcome to Gurbiz. Our Digital<br/>
            Platform has been built<br/>
            specially to help small and<br/>
            micro-businesses scale-up<br/>
            through Value Addition Services<br/>
            and Network effects.
            </Typography>
          </Grid>

          <Grid item xs={6} lg={2} md={6} marginLeft='2%'>
          <Typography variant='h5' sx={{color:'white',fontFamily:'Source Sans Pro', fontWeight:'bold', marginLeft:'15%'}}>
          Our links
          
          </Typography>
          <div style={{display:'flex', marginLeft:'15%'}}>
          <Box sx={{height:'4px', width:'15px', backgroundColor:'white', borderRadius:'4px',  marginRight:'2%'}}></Box>
          <Box sx={{height:'4px', width:'45px', backgroundColor:'white', borderRadius:'4px', marginBottom:'6%'}}></Box>
          </div>
         
          <ul><a href='#' style={{color:'white',marginTop:'15px',fontSize:'17px',textDecoration:'none',fontFamily:'Source Sans Pro'}}>Home</a></ul>
          <ul><a href='#' style={{color:'white',marginTop:'15px',fontSize:'17px',textDecoration:'none',fontFamily:'Source Sans Pro'}}>About Us</a></ul>
          <ul><a href='#' style={{color:'white',marginTop:'15px',fontSize:'17px',textDecoration:'none',fontFamily:'Source Sans Pro'}}>Service</a></ul>
          <ul><a href='#' style={{color:'white',marginTop:'15px',fontSize:'17px',textDecoration:'none',fontFamily:'Source Sans Pro'}}>Team</a></ul>
          
          
          
          </Grid>

          <Grid item xs={6} lg={1.7} md={6} marginLeft='2%'>
          <Typography variant='h5' sx={{color:'white',fontFamily:'Source Sans Pro', fontWeight:'bold', marginLeft:'20%'}}>
          Our Services
          </Typography>
          <div style={{display:'flex', marginLeft:'20%'}}>
          <Box sx={{height:'4px', width:'15px', backgroundColor:'white', borderRadius:'4px', marginBottom:'6%', marginRight:'2%'}}></Box>
          <Box sx={{height:'4px', width:'45px', backgroundColor:'white', borderRadius:'4px', marginBottom:'6%'}}></Box>
          </div>
          <ul><a href='#' style={{color:'white',marginTop:'15px',fontSize:'17px',textDecoration:'none',fontFamily:'Source Sans Pro'}}>Education</a></ul>
          <ul><a href='#' style={{color:'white',marginTop:'15px',fontSize:'17px',textDecoration:'none',fontFamily:'Source Sans Pro'}}>Skills</a></ul>
          <ul><a href='#' style={{color:'white',marginTop:'15px',fontSize:'17px',textDecoration:'none',fontFamily:'Source Sans Pro'}}>Art & Creativity</a></ul>
          <ul><a href='#' style={{color:'white',marginTop:'15px',fontSize:'17px',textDecoration:'none',fontFamily:'Source Sans Pro'}}>Agriculture</a></ul>
          </Grid>

          <Grid item xs={6} lg={1.5} md={6} marginLeft='4%'>
          <Typography variant='h5' sx={{color:'white',fontFamily:'Source Sans Pro', fontWeight:'bold',marginLeft:'20%'}}>
          Other links
          </Typography>
          <div style={{display:'flex',marginLeft:'20%'}}>
          <Box sx={{height:'4px', width:'15px', backgroundColor:'white', borderRadius:'4px',  marginRight:'2%'}}></Box>
          <Box sx={{height:'4px', width:'45px', backgroundColor:'white', borderRadius:'4px', marginBottom:'6%'}}></Box>
          </div>
          <ul><a href='#' style={{color:'white',marginTop:'15px',fontSize:'17px',textDecoration:'none',fontFamily:'Source Sans Pro'}}>FAQ</a></ul>
          <ul><a href='#' style={{color:'white',marginTop:'15px',fontSize:'17px',textDecoration:'none',fontFamily:'Source Sans Pro'}}>Portfolio</a></ul>
          <ul><a href='#' style={{color:'white',marginTop:'15px',fontSize:'17px',textDecoration:'none',fontFamily:'Source Sans Pro'}}>Privacy Policy</a></ul>
          <ul><a href='#' style={{color:'white',marginTop:'15px',fontSize:'17px',textDecoration:'none',fontFamily:'Source Sans Pro'}}>Terms & Conditions</a></ul>
          <ul><a href='#' style={{color:'white',marginTop:'15px',fontSize:'17px',textDecoration:'none',fontFamily:'Source Sans Pro'}}>Support</a></ul>
          </Grid>

          <Grid item xs={1} lg={1.5}/>
          <Grid item xs={1} lg={1.6}/>
          <Grid item xs={12} lg={5} sx={{ marginTop:'3%'}}>
          <Typography variant='h5' sx={{color:'white',fontFamily:'Source Sans Pro', fontWeight:'bold'}}>
          Subscribe to Our Newsletter for Latest Updates
          </Typography>
          <div style={{display:'flex'}}>
          <Box sx={{height:'4px', width:'15px', backgroundColor:'white', borderRadius:'4px',  marginRight:'1%'}}></Box>
          <Box sx={{height:'4px', width:'45px', backgroundColor:'white', borderRadius:'4px'}}></Box>
          </div>
          </Grid>

          <Grid item xs={12} lg={4} marginTop='3%' marginBottom='1.5%'>
          
          <Subscribe/>
          </Grid>
        </Grid>
        
      
        <Grid container
        sx={{backgroundColor:'#b3a1ff', display:'flex', justifyContent:'center', }}
        >
        <Typography sx={{fontSize:'17px',color:'white',fontFamily:'Source Sans Pro',justifyContent:'center', margin:'0.5%'}}>
        Copyright <CopyrightIcon sx={{marginTop:'0.5%'}}/>2021 GL Software Services (OPC) Private Limited. All rights reserved.
        </Typography>
        </Grid>
        </>
    )
}


//
export function Subscribe(){
  return(
     
        <div className='subscribe-input'>
          <input type='email' placeholder='Email Address'/>
          <a href='#' style={{textDecoration:'none'}}>Subscribe Now</a>
        </div>
      
  )
}