import React from 'react';
import { Grid, Typography, Box, Avatar } from '@mui/material';
import img55 from "../Images/img55.png"
import './f.css';
import './styles.css'


const Features = () => {
  return (
    <Grid container padding={"2%"} sx={{ paddingTop: '120px' }}>
      <Grid item lg={1}></Grid>
      <Grid item xs={12} lg={5} md={7} sm={7}>
        <Box sx={{ backgroundColor: 'rgb(137 110 255 / 10%)', padding: '10px 30px', display: 'inline-block', borderRadius: '10px', marginBottom: '20px' }}>
          <Typography sx={{ fontSize: '17px', fontWeight: '500', color: '#896eff' }}> Features</Typography>
        </Box>

        <Typography className='f' sx={{ fontSize: '38px', color: '#1f2471', fontFamily: 'Source Sans Pro', fontWeight: '700', lineHeight: '1.2', marginBottom: '25px' }}>
          Our Working Process To Help You Boost Your Business
        </Typography>
        <Grid container >
          <Grid item xs={2} lg={2}></Grid>
          <Grid item xs={10} lg={10} md={12} sm={12}>
            <Typography sx={{ fontSize: '1.5625rem', color: '#1f2471', fontFamily: 'Source Sans Pro', fontWeight: '700', lineHeight: '1.2', marginBottom: '0.5rem' }}>
              Innovate
            </Typography>
            <Typography sx={{ fontFamily: 'Source Sans Pro', fontSize: '17px', color: '#505489', lineHeight: '1.6', fontWeight: '400', marginBottom: '30px' }}>
              By providing Value addition to your already existing products and services in Agriculture, Education, and Art & Creativity, we bring forth new Innovations, overcoming the already existing challenges.
            </Typography>
          </Grid>

        </Grid>
        <Grid container >
          <Grid item xs={2} lg={5}></Grid>
          <Grid item xs={10} lg={7} md={12} sm={12}>
            <Typography sx={{ fontSize: '1.5625rem', color: '#1f2471', fontFamily: 'Source Sans Pro', fontWeight: '700', lineHeight: '1.2', marginBottom: '0.5rem' }}>
              Implement
            </Typography>
            <Typography sx={{ fontFamily: 'Source Sans Pro', fontSize: '17px', color: '#505489', lineHeight: '1.6', fontWeight: '400', marginBottom: '30px' }}>
              Through our Learn, Earn and Create Platforms, we have fully implemented solutions for you to not only Educate yourself, but also generate your Income sources and sell your Creatives.
            </Typography>
          </Grid>

        </Grid>
        <Grid container >
          <Grid item xs={2} lg={2}></Grid>
          <Grid item xs={10} lg={10} md={12} sm={12}>
            <Typography sx={{ fontSize: '1.5625rem', color: '#1f2471', fontFamily: 'Source Sans Pro', fontWeight: '700', lineHeight: '1.2', marginBottom: '0.5rem' }}>
              Promote
            </Typography>
            <Typography sx={{ fontFamily: 'Source Sans Pro', fontSize: '17px', color: '#505489', lineHeight: '1.6', fontWeight: '400', marginBottom: '30px' }}>
              Providing an Open Marketplace to promote and Brand your businesses through Our Innovative Platform, we eliminate the need for you to search a marketplace on your own.
            </Typography>
          </Grid>

        </Grid>
      </Grid>
      <Grid item xs={12} lg={5}  md={5} sm={5}>

        <img className='swing' src={img55} alt='image' style={{ height: '90%', width: '100%', marginTop: '10%' }} />
      </Grid>
      <Grid item lg={1}></Grid>
    </Grid>
  )
}

export default Features
