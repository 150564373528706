import React from 'react';
import {Grid,Typography } from '@mui/material';
import bg from '../Images/bg.png';
import bg1 from '../Images/bg1.png'
import bg2 from '../Images/bg2.png';
import  './r.css';
import pic11 from '../Images/pic11.png';
import pic22 from '../Images/pic22.png';

const Realizeyourvision = () => {
  return (
    <Grid id="Home"  container padding={"3%"} sx={{backgroundImage:`url(${bg})`,paddingTop:'70px',marginBottom:'50px'}}>
         <Grid item  lg={1}></Grid>
         <Grid item xs={12} lg={5} md={5} sm={5} sx={{backgroundImage:`url(${bg1})`}}>
             <Typography className='move-text' sx={{fontSize:'18px',letterSpacing:'2px',color:'#896eff',fontFamily:'Source Sans Pro',fontWeight:'600',marginBottom:'12px'}}>
                REALIZE YOUR VISION
             </Typography>
            
               <Typography className='r' sx={{fontSize:'3.375rem',letterSpacing:'0.04em',color:'#1f2471',fontFamily:'Source Sans Pro',fontWeight:'700',lineHeight:'1.2',marginBottom:'20px'}}>
                Build Stunning <span style={{color:'#896eff'}}>Businesses</span> With <span style={{color:'#00a551'}}>GURBIZ</span>
             </Typography>
            
             
             <Typography className='ultimate' sx={{fontFamily:'Source Sans Pro',fontSize:'18px',color:'#505489',lineHeight:'1.6',fontWeight:'400',marginBottom:'30px'}}>
             The ultimate Digital Engine: Creating value for both collaborators and customers.
             </Typography>
         </Grid>
         <Grid position={"relative"} item  xs={12} lg={5} md={7} sm={7} sx={{backgroundImage:`url(${bg2})`}} >
            {/* <img src={pic11} alt="" style={{height:'100%',width:'100%',position:'unset'}}/> */}
            <img className='swing' src={pic11} alt="" style={{height:'120%',width:'110%',position:'absolute',marginTop:'-4%'}}/>
            <img className='swing' src={pic22} alt="" style={{height:'140%',width:'110%',position:'absolute',marginTop:'-16%'}}/>
            
         </Grid>
         <Grid item  lg={1}></Grid>
    </Grid>
  )
}

export default Realizeyourvision
