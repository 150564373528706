import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import Grid from '@mui/material/Grid';
import Carousel3 from '../Images/Carousel3.png';
import Carousel1 from '../Images/Carousel1.png';
import Carousel4 from '../Images/Carousel4.png';
import ResumeImage from '../Images/ResumeImage.png';
import Blog from './Blog';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  {
    
    picture: Carousel1,
    subhead: 'A professional profile picture helps you stand out'
    // imgPath:
    //   'https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60',
  },
  {
   
    picture: ResumeImage,
    subhead: 'Distinguish yourself and create a first impression',
    imgPath:
      'https://images.unsplash.com/photo-1538032746644-0212e812a9e7?auto=format&fit=crop&w=400&h=250&q=60',
  },
  {
    
    picture: Carousel3,
    subhead:'Your top skills help companies to identify and shortlist you',
    imgPath:
      'https://images.unsplash.com/photo-1537996194471-e657df975ab4?auto=format&fit=crop&w=400&h=250&q=80',
  },
  {
    
    picture: Carousel4,
    subhead: 'Present yourself and grab 1-x attention from companies',
    imgPath:
      'https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60',
  },
];

function SwipeableTextMobileStepper() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Box sx={{ width:'auto', flexGrow: 1 }}>
    <Grid item lg={12} xs={12} display={"flex"} justifyContent={'center'} marginTop='2%'>
         <Box sx={{backgroundColor:'rgb(137 110 255 / 10%)',padding:'10px 30px',display:'inline-block',borderRadius:'10px',marginBottom:'30px'}}>
             <Typography sx={{fontSize:'18px',fontWeight:'600',color:'#896eff'}}>Latest Blog & News</Typography>
            </Box> 
      </Grid> 
      <Paper
        square
        elevation={0}
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: 50,
          pl: 2,
          bgcolor: 'background.default',
        }}
      >
        <Typography fontFamily='poppins'>{images[activeStep].label}</Typography>
      </Paper>
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {images.map((step, index) => (
          <div key={step.label}>
            {Math.abs(activeStep - index) <= 0.8 ? (
                <Grid container display='flex'>
                <Blog/>
                </Grid>
            //   <Box
            //     component="img"
            //     sx={{
            //       height: 255,
            //       display: 'block',
            //       maxWidth: 400,
            //       overflow: 'hidden',
            //       width: '100%',
            //     }}
            //     src={step.imgPath}
            //     alt={step.label}
            //   />
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
     
    </Box>
  );
}

export default SwipeableTextMobileStepper;