import React from 'react';
import {Grid,Typography ,Box,Avatar} from '@mui/material';
import mrgurpreetsirsign from '../Images/mrgurpreetsirsign.jpeg';
import mrgurpreetsinghminipic from '../Images/mrgurpreetsinghminipic.jpeg';
import img44 from "../Images/img44.png"
import './a.css';
import './styles.css'


const AboutUs = () => {
  return (
    <Grid id="AboutUs"  container padding={"3%"} >
    <Grid item  lg={1}></Grid>
    <Grid item xs={12} lg={5} sm={6} md={6}>
       <img className='swing'src={img44} alt='image' style={{height:'90%', width:'100%'}}/>
        
        </Grid>
    
   
   
   
    <Grid item  xs={12} lg={5} md={6} sm={6} sx={{}} >
    <Box sx={{backgroundColor:'rgb(137 110 255 / 10%)',padding:'10px 30px',display:'inline-block',borderRadius:'10px',marginBottom:'20px'}}>
                 <Typography sx={{fontSize:'17px',fontWeight:'500',color:'#896eff'}}> About Us</Typography>
                </Box> 
    
        <Typography className='a' sx={{fontSize:'38px',color:'#1f2471',fontFamily:'Source Sans Pro',fontWeight:'700',lineHeight:'1.2',marginBottom:'25px'}}>
        How we help Great Companies Enlarge their Revenues.
        </Typography>
        <Typography sx={{fontFamily:'Source Sans Pro',fontSize:'15px',color:'#505489',lineHeight:'1.6',fontWeight:'400',marginBottom:'10px'}}>
        Gurbiz is a brand owned by GL Software Services (OPC) Private Limited. The idea of an orchestrated Digital Platform began with an aim to not only bring together small businesses, farming sectors, colleges, economically weak students, coaching centres, freelancing tutors, artists & artisans etc. but also salvage them from losing their identity.
  </Typography>
  <Typography sx={{fontFamily:'Source Sans Pro',fontSize:'15px',color:'#505489',lineHeight:'1.6',fontWeight:'400',marginBottom:'30px'}}>
Through services like Value Addition, Product & Service Branding, Marketing Strategies, and Reach, Gurbiz offers an aggregation from Unorganized Sectors to Organized ones. So far, this platform has successfully integrated Education, Art & Creation, and Agricultural marketplaces with extensive, scalable, flexible and agile Network effects.
      
   </Typography>
   <img src={mrgurpreetsirsign} alt="" style={{marginLeft:'77px'}}/>
   <div style={{display:'flex'}}>
     <Avatar alt="" src={mrgurpreetsinghminipic} sx={{ width: 64, height: 64, borderRadius:'50%',boxShadow:'0 0px 10px 4px rgb(31 66 135 / 10%)' ,border:'5px solid #fff'}} />
     <Typography sx={{fontSize:'1.5625rem',color:'#1f2471',fontFamily:'Source Sans Pro',fontWeight:'700',lineHeight:'1.2',marginBottom:'25px',marginTop:'4%',marginLeft:'3%'}}>
     CMD, Gurpreet Singh
     </Typography>
   </div>
  
    </Grid>
    
</Grid>

  )
}

export default AboutUs
