import React from 'react';
import {Grid, Button, Typography, TextField,Box} from '@mui/material';
import ContactImage from '../Images/ContactImage.png';

import { MuiThemeProvider, ThemeProvider, createTheme } from '@material-ui/core/styles';
import { orange, purple } from '@material-ui/core/colors';

import { roundTextFieldStylesHook } from '@mui-treasury/styles/textField/round';


const theme = createTheme({
  palette: {
    primary: {
      main: orange[700],
    },
    secondary: {
      main: purple[700],
    },
  },
});


const TextFields = () => {
  const inputBaseStyles = roundTextFieldStylesHook.useInputBase();
  const inputLabelStyles = roundTextFieldStylesHook.useInputLabel();
  const helperTextStyles = roundTextFieldStylesHook.useHelperText();
  return (
    <div>
      <TextField
        label={'Primary field'}
        placeholder={'Placeholder'}
        helperText={'Helper Text'}
        margin={'normal'}
        InputLabelProps={{ shrink: true, classes: inputLabelStyles }}
        InputProps={{ classes: inputBaseStyles, disableUnderline: true }}
        FormHelperTextProps={{ classes: helperTextStyles }}
      />
      
      
    </div>
  );
};

export const RoundTextFieldStyle = () => (
  <MuiThemeProvider theme={theme}>
    <TextFields />
  </MuiThemeProvider>
  
);





export default function Contact(){

 

    return(
        <Grid id="Contact"  container marginBottom={5}>
        
        <Grid item lg={12} xs={12} display={"flex"} justifyContent={'center'} marginTop='2%'>
        <Box sx={{backgroundColor:'rgb(137 110 255 / 10%)',padding:'10px 30px',display:'inline-block',borderRadius:'10px',marginBottom:'30px'}}>
            <Typography sx={{fontSize:'18px',fontWeight:'600',color:'#896eff'}}>Contact Us</Typography>
           </Box> 
     </Grid> 
            
           <Grid item xs={1} lg={1.5}/>
           <Grid item xs={12} lg={9} md={12} marginBottom='3%'>
             <Typography variant='h4'
             sx={{fontFamily:'Source Sans Pro', color:'#1f2471',fontWeight:'bold' }}>
             We welcome established<br/>
             companies and budding<br/>
             enterprises to join us.
             </Typography>
           </Grid>
           <Grid item xs={1} lg={1.5}/>
           
             
           <Grid item xs={1} lg={1.5}/>
           <Grid xs={12} lg={4}>
             <Grid item container >
                <Grid xs={12} marginBottom='2%'>
                <TextField id="outlined-basic" label="Name of Organization" variant="outlined" 
                sx={{width:'95%',
                  '& .MuiOutlinedInput-root':{
                    borderRadius:'35px', boxShadow: "0 0px 10px 4px rgb(31 66 135 / 10%)",
                    "&>fieldset":{
                      border:'none'
                    }
                  }
              }}
                
                />
               
                
                </Grid>

                <Grid xs={6} marginBottom='2%'>
                <TextField id="outlined-basic" label="First Name" variant="outlined" 
                sx={{width:'90%', '& .MuiOutlinedInput-root':{
                  borderRadius:'35px', boxShadow: "0 0px 10px 4px rgb(31 66 135 / 10%)",
                  "&>fieldset":{
                    border:'none'
                  }

                }
              }}
                />
                
                </Grid>

                <Grid xs={6} marginBottom='2%'>
                <TextField id="outlined-basic" label="Last Name" variant="outlined" 
                sx={{width:'90%', '& .MuiOutlinedInput-root':{
                  borderRadius:'35px', boxShadow: "0 0px 10px 4px rgb(31 66 135 / 10%)",
                  "&>fieldset":{
                    border:'none'
                  }
                }}}
                />
                
                </Grid>

                <Grid xs={6} marginBottom='2%'>
                <TextField id="outlined-basic" label="Position" variant="outlined" 
                sx={{width:'90%', '& .MuiOutlinedInput-root':{
                  borderRadius:'35px', boxShadow: "0 0px 10px 4px rgb(31 66 135 / 10%)",
                  "&>fieldset":{
                    border:'none'
                  }
                }}}
                />
                
                </Grid>

                <Grid xs={6} marginBottom='2%'>
                <TextField id="outlined-basic" label="Industry you cater to" variant="outlined" 
                sx={{width:'90%', '& .MuiOutlinedInput-root':{
                  borderRadius:'35px', boxShadow: "0 0px 10px 4px rgb(31 66 135 / 10%)",
                  "&>fieldset":{
                    border:'none'
                  }
                }}}
                />
                
                </Grid>

                <Grid xs={6}marginBottom='2%'>
                <TextField id="outlined-basic" label="Email Address" variant="outlined" 
                sx={{width:'90%', '& .MuiOutlinedInput-root':{
                  borderRadius:'35px', boxShadow: "0 0px 10px 4px rgb(31 66 135 / 10%)",
                  "&>fieldset":{
                    border:'none'
                  }
                }}}
                />
                
                </Grid>
                <Grid xs={6} marginBottom='2%'>
                <TextField id="outlined-basic" label="Contact No." variant="outlined" 
                sx={{width:'90%', '& .MuiOutlinedInput-root':{
                  borderRadius:'35px', boxShadow: "0 0px 10px 4px rgb(31 66 135 / 10%)",
                  "&>fieldset":{
                    border:'none'
                  }
                }}}
                />
                
                </Grid>
                <Grid xs={12} marginBottom='1%'>
                <TextField id="outlined-basic" label="Message" variant="outlined" 
                sx={{width:'95%', '& .MuiOutlinedInput-root':{
                  borderRadius:'10px', boxShadow: "0 0px 10px 4px rgb(31 66 135 / 10%)", height: '100px',
                  "&>fieldset":{
                    border:'none'
                  }
                }}}
                />
                
                </Grid>
                <Grid xs={12}>
                <Button variant='text' 
                sx={{fontFamily:'Source Sans Pro',color:'#896eff', backgroundColor:'white',
                height:'50px', width:'140px', fontSize:'18px', fontWeight:'bold'
               }}>
                 Join Now
                </Button>
                </Grid>
             </Grid>
           </Grid>
           <Grid item xs={1} lg={0.2}/>

           <Grid xs={12} lg={5} marginTop='-6%'>
             <img className='swing' src={ContactImage} alt='image' style={{height:'90%', width:'100%'}}/>
           </Grid>
           <Grid item xs={1} lg={1}/>

           
        </Grid>

    )
}
