import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Realizeyourvision from './Realizeyourvision';
import logo from '../Images/logo.png';
import { Grid } from '@mui/material';
import './i.css';
import CloseIcon from '@mui/icons-material/Close';
    

const drawerWidth = 240;
const navItems = [{Name:'Home', Link:"#Home"},{Name:'Services', Link:'#Services'}, {Name:'About Us', Link:'#AboutUs'},{Name:'Career', Link:'#Career'}, {Name:'Contact', Link:'#Contact'}];

function Nav (props) {
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
  
    const handleDrawerToggle = () => {
      setMobileOpen(!mobileOpen);
    };
  
    const drawer = (

      <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
        <img src={logo} alt="" style={{width:'70%',height:'auto',marginTop:'2%'}}/>
        <Divider />
        <List>
          {navItems.map((item) => (
         <a href={item.Link} style={{textDecoration:'none', color:'black'}}><ListItem key={item.Name} disablePadding>
              <ListItemButton   sx={{ textAlign: 'center' }}>
                <ListItemText primary={item.Name} />
              </ListItemButton>
            </ListItem></a>   
          ))}
        </List>
       
      </Box>
      
    );
  
    const container = window !== undefined ? () => window().document.body : undefined;
  
    return (
      <Box sx={{ display: 'flex' }}>
        <AppBar component="nav" sx={{bgcolor:'#fff'}}>
          <Toolbar >
           
            <Grid container item xs={12} lg={12} sm={12} md={12}>
               <Grid item lg={6} xs={6} sm={3} md={3}>
               <img className='i' src={logo} alt="" style={{width:'20%',marginLeft:'12%' }}/>
            </Grid>
           <Grid item lg={6} xs={6} sm={9} md={9}>
             <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
              {navItems.map((item) => (
                <a  href={item.Link} style={{textDecoration:'none'}}> <Button key={item.Name} sx={{ color: 'black',fontSize:'16px',marginTop:'1%',marginLeft:'2%' }}>
                 {item.Name}
                </Button></a>
              ))}
            </Box>
           </Grid>
            </Grid>
           
           {!mobileOpen? <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ display: { sm: 'none' },color:'black' }}
            >
              <MenuIcon />
            </IconButton>:
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ display: { sm: 'none' },color:'black' }}
            >
              <CloseIcon/>
            </IconButton>}
            
          </Toolbar>
        </AppBar>
        <Box component="nav">
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
          >
            {drawer}
            
          </Drawer>
        </Box>
        <Box component="main" sx={{ p: 0 }}>
          <Toolbar />
       
        </Box>
      </Box>
    );
  }
  
 
  
export default Nav
