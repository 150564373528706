import React, { useState } from 'react';
import { Grid, Box, Typography, Card } from '@mui/material';
import bg4 from "../Images/bg4.png";
import Learn from '../Images/Learn.svg';
import Earn from '../Images/Earn.svg';
import Create from '../Images/Create.svg';
import './p.css';

const Platform = () => {
  const [visible, setVisible] = useState('hidden')
  const [visible1, setVisible1] = useState('hidden')
  const [visible2, setVisible2] = useState('hidden')
  return (
    <Grid id="Career" container paddingTop={'5%'}>
      <Grid container sx={{ backgroundImage: `url(${bg4})`, paddingTop: '100px', paddingBottom: '70px', backgroundColor: '#896eff' }}>
        <Grid item xs={12} lg={12} md={12} sm={12}>
          <div style={{ justifyContent: 'center', display: 'flex' }}>
            <Box sx={{ backgroundColor: 'rgba(250, 248, 242, 0.1)', padding: '10px 30px', borderRadius: '10px', marginBottom: '20px', width: 'fit-content' }}>
              <Typography sx={{ fontSize: '17px', fontWeight: '500', color: '#fff' }}> Platform</Typography>
            </Box>
          </div>
          <Typography className='p' sx={{ fontSize: '38px', color: '#fff', fontFamily: 'Source Sans Pro', fontWeight: '700', lineHeight: '1.2', marginBottom: '30px', textAlign: 'center' }}>
            Our Extensive Platforms for your benefit
          </Typography>
        </Grid>


        <Grid container >
          <Grid item lg={1.5}></Grid>
          <Grid container item lg={9} xs={12} md={12} sm={12} display={'flex'} justifyContent={'space-between'} marginBottom={'30px'}>

            <Grid item xs={12} lg={3.75} md={3.75} sm={3.75} margin={'5px'} >
              <Card onMouseOver={() => { setVisible('visible') }} onMouseLeave={() => { setVisible('hidden') }} sx={{ borderRadius: '10px', position: 'relative' }}>
                <div style={{ backgroundColor: 'rgb(137 110 255 / 60%)', visibility: visible, width: '100%', padding: '27% 0', opacity: '1', position: 'absolute' }}>
                  <p style={{ textAlign: 'center', fontSize: '36px', color: '#fff', fontFamily: 'Source Sans Pro', fontWeight: '700' }}>Learn</p>
                </div>

                <img src={Learn} alt="" style={{width:'100%'}}/>
              </Card>
            </Grid>
            <Grid item xs={12} lg={3.75} md={3.75} sm={3.75} margin={'5px'}>
              <Card onMouseOver={() => { setVisible1('visible') }} onMouseLeave={() => { setVisible1('hidden') }} sx={{ borderRadius: '10px', position: 'relative' }}>
                <div style={{ backgroundColor: 'rgb(137 110 255 / 60%)', visibility: visible1, width: '100%', padding: '27% 0', opacity: '1', position: 'absolute' }}>
                  <p style={{ textAlign: 'center', fontSize: '36px', color: '#fff', fontFamily: 'Source Sans Pro', fontWeight: '700' }}>Earn</p>
                </div>
                <img src={Earn} alt="" style={{width:'100%'}} />
              </Card>
            </Grid>
            <Grid item xs={12} lg={3.75} md={3.75} sm={3.75} margin={'5px'} >
              <Card onMouseOver={() => { setVisible2('visible') }} onMouseLeave={() => { setVisible2('hidden') }} sx={{ borderRadius: '10px', position: 'relative' }}>
                <div style={{ backgroundColor: 'rgb(137 110 255 / 60%)', visibility: visible2, width: '100%', padding: '27% 0', opacity: '1', position: 'absolute' }}>
                  <p style={{ textAlign: 'center', fontSize: '36px', color: '#fff', fontFamily: 'Source Sans Pro', fontWeight: '700' }}>Create</p>
                </div>
                <img src={Create} alt="" style={{width:'100%'}}/>
              </Card>
            </Grid>
          </Grid>
          <Grid item lg={1.5}></Grid>

        </Grid> </Grid></Grid>
  )
}

export default Platform
